window.caseStudiesFilter = () => {
  const filterButtons = document.querySelectorAll('.jsCaseStudyFilterBtn');
  const caseStudiesList = document.querySelector('.jsCaseStudiesList');
  const caseStudyLoader = document.querySelector('.jsCaseStudyLoader');

  const createCaseStudyBox = ({ firstImage, secondImage, mainImage, title, industries, url, testimonial }) => {
    const caseStudyBoxElement = document.querySelector('.jsCaseStudiesExampleList').children[0].cloneNode(true);
    const titleElement = caseStudyBoxElement.querySelector('.jsCaseStudyBoxTitle');
    const industriesListElement = caseStudyBoxElement.querySelector('.jsCaseStudyBoxIndustriesList');
    const authorImgElement = caseStudyBoxElement.querySelector('.jsCaseStudyBoxAuthorImg');
    const authorNameElement = caseStudyBoxElement.querySelector('.jsCaseStudyBoxAuthorName');
    const authorNameLinkedIn = caseStudyBoxElement.querySelector('.jsCaseStudyBoxAuthorNameLinkedIn');
    const authorTextElement = caseStudyBoxElement.querySelector('.jsCaseStudyBoxAuthorText');
    const companyElement = caseStudyBoxElement.querySelector('.jsCaseStudyBoxCompany');
    const firstImageElement = caseStudyBoxElement.querySelector('.jsCaseStudyFirstImage');
    const secondImageElement = caseStudyBoxElement.querySelector('.jsCaseStudySecondImage');
    const imagesWrapper = caseStudyBoxElement.querySelector('.jsCaseStudyImageWrapper');
    const testimonialElement = caseStudyBoxElement.querySelector('.jsCaseStudyTestimonial');
    const linkedInElement = caseStudyBoxElement.querySelector('.jsCaseStudyLinkedIn');
    const mainImageElement = caseStudyBoxElement.querySelector('.jsCaseStudyMainImage');

    imagesWrapper.href = url;

    titleElement.innerText = title;
    titleElement.href = url;

    industries.forEach(({ name, link }) => {
      const listItemElement = document.createElement('li');
      const linkElement = document.createElement('a');
      linkElement.innerText = name;
      linkElement.href = link;
      linkElement.className = 'case-studies-box__tag';

      listItemElement.appendChild(linkElement);
      industriesListElement.appendChild(listItemElement);
    });

    caseStudiesList.appendChild(caseStudyBoxElement);

    // Handle images
    if (firstImage && secondImage) {
      firstImageElement.src = firstImage;
      secondImageElement.src = secondImage;
    } else {
      mainImageElement.src = mainImage;
      imagesWrapper.classList.add('case-study-image--no-animation');
    }

    // Handle testimonial
    if (!testimonial) {
      testimonialElement.style.display = 'none';
    } else {
      authorImgElement.src = testimonial.image_url;
      authorTextElement.innerText = testimonial.text;
      authorNameElement.innerText = testimonial.name;
      companyElement.innerText = testimonial.position;

      if (testimonial.linkedIn) {
        linkedInElement.href = testimonial.linkedIn;
      } else {
        linkedInElement.style.display = 'none';
      }
    }
  };

  const ACTIVE_BTN_CLASS = 'buttonBig--caseStudyActive';
  let activeButton = filterButtons[0];

  const setBoxesMaxHeight = () => {
    const boxes = document.querySelectorAll('.jsCaseStudiesList li');
    const x = document.querySelector('.jsCaseStudiesExampleList li');
    let maxHeight = 0;
    document.querySelector('.jsCaseStudiesExampleList').style.display = 'block';
    maxHeight = x.getBoundingClientRect().height;
    document.querySelector('.jsCaseStudiesExampleList').style.display = 'none';

    boxes.forEach(el => {
      const imageHeight = el.querySelector('.case-study-image__second-image');
      if (!imageHeight) return;
      el.style.maxHeight = `${maxHeight}px`;
    });
  };
  setBoxesMaxHeight();

  addEventListener('resize', setBoxesMaxHeight);

  let isLoading = false;
  const fetchCaseStudies = industryId => {
    if (isLoading) return;
    isLoading = true;
    caseStudiesList.innerHTML = '';
    caseStudyLoader.style.display = 'block';

    fetch(`/api/case-studies/${industryId}/`)
      .then(response => response.json())
      .then(data => {
        isLoading = false;
        data.forEach(el => {
          createCaseStudyBox({
            url: el.url_path,
            firstImage: el.box_first_image,
            secondImage: el.box_second_image,
            title: el.box_title,
            industries: el?.industries?.map(el => ({ name: el.title, link: el.url })) ?? [],
            testimonial: el.testimonial,
            mainImage: el.main_image,
          });
        });

        caseStudyLoader.style.display = 'none';
        setBoxesMaxHeight();
      })
      .catch(error => {
        isLoading = false;
        console.error('Error:', error);
        caseStudyLoader.style.display = 'none';
      });
  };

  filterButtons.forEach(el =>
    el.addEventListener('click', e => {
      e.preventDefault();
      if (activeButton) {
        activeButton.classList.remove(ACTIVE_BTN_CLASS);
      }
      el.classList.add(ACTIVE_BTN_CLASS);
      fetchCaseStudies(el.dataset.industryId);
      activeButton = el;
    }),
  );
};
